import axios from "axios";

export const instagramApi = axios.create({
  baseURL: "https://api.instagram.com",
});
export default {
  requestAccess(code) {
    const payload = {
      redirect_uri: "https://mobile.photonow.com.br/user/auth-instagram/",
      code,
    };
    return axios.post(
      `https://photo-now-api.onrender.com/access_token`,
      payload
    );
  },
  getFotoForId(id, token) {
    return axios.get(
      `https://graph.facebook.com/${id}?fields=media_url,media_type,caption&access_token=${token}`
    );
  },
  getPaginationPhotos(token) {
    return axios.get(
      `https://graph.facebook.com/me/media?fields=id,caption&access_token=${token}`
    );
  },
};
