<template>
  <div class="bg-gray-50">
    <v-modal v-if="modal">
      <div class="text-center">
        <div class="font-bold pb-2 text-gray-900">Atenção</div>
        <div class="font-normal py-1 pb-2 text-gray-700">
          {{ modalMensagem }}
        </div>
        <div class="py-2 text-center">
          <button
            @click="closeModal()"
            class="rounded-md bg-pink-600 py-2 px-3 text-white"
          >
            Fechar
          </button>
        </div>
      </div>
    </v-modal>
    <div class="py-4 font-bold bg-white shadow-md relative">
      <div class="text-xl font-bold text-pink-600">Fotos instagram</div>
      <div class="pt-4 font-light">
        Você pode selecionou {{ getQuantidadeFotoSelecionada }} de
        {{ getQuantidadeMaxima }} fotos.
      </div>
    </div>
    <div class="mb-28">
      <div class="py-4 text-center text-xl font-bold">Imagens Disponíveis</div>
      <div class="flex flex-wrap">
        <div
          class="w-1/3 h-auto relative"
          :key="index"
          v-for="(item, index) in getListaDeFotos"
        >
          <div
            class="absolute justify-center items-center w-full h-full bg-black bg-opacity-75 text-white text-md font-bold mx-auto"
            :class="{
              flex: listaMapSelected.includes(index),
              hidden: !listaMapSelected.includes(index),
            }"
          >
            <div class="flex-none">
              <div class="block text-sm py-1">Selecionado</div>
              <div class="block text-center py-1">
                <button
                  class="text-sm p-1 w-full block stroke-current text-pink-600"
                  @click="removeFoto(item, index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-12 w-12 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <img
            @click="selecionaImagem(item.media_url, index)"
            class="cursor-pointer mx-auto w-full h-auto"
            :src="item.media_url"
          />
        </div>
      </div>
    </div>
    <div
      class="fixed w-full p-4 bg-white bg-opacity-90 bottom-0 self-center"
      v-if="getQuantidadeMaxima === getQuantidadeMaxima"
    >
      <button
        @click="uploadImages()"
        :disable="loadingBtn"
        class="bg-pink-600 font-bold text-white px-5 py-2 rounded-full"
      >
        concluir
      </button>
    </div>
  </div>
</template>

<script>
//
import serviceInstagram from "@/service/instagram";
import { storageRef, firebase, db } from "@/service/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import moment from "moment";
import etapa from "@/constants/etapaCompra.js";
export default {
  data() {
    return {
      listagemDeFotos: [],
      listaDeFotosSelecionadas: [],
      listaMapSelected: [],
      modalMensagem: "",
      modal: false,
      loadingBtn: false,
      contagem: 0,
    };
  },
  beforeMount() {
    console.log("component fotos-instagram");
    this.requestMedias();
  },
  mounted() {
    this.REQUEST_QRCODE();
  },
  computed: {
    getListaDeFotos() {
      return this.listagemDeFotos.filter((item) => item.media_type == "IMAGE");
    },
    getQuantidadeMaxima() {
      console.log(
        "Maximo de  fotos: ",
        this.$store.getters["compra/getCompra"].qtde_foto
      );
      return this.$store.getters["compra/getCompra"].qtde_foto;
      // return this.$store.getters["compra/getCompra"].qtde_foto;
    },
    getQuantidadeFotoSelecionada() {
      return this.listaDeFotosSelecionadas.length;
    },
  },
  methods: {
    async REQUEST_QRCODE() {
      //necessario ?
      const docRef = doc(db, "compras", localStorage.getItem("compra_id"));
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.$store.commit("compra/setCompra", docSnap.data());
      } else {
        this.$router.push("/");
      }
    },
    uploadImages() {
      this.loadingBtn = true;
      this.listaDeFotosSelecionadas.forEach((foto, index) => {
        this.createTaskUpload(foto.src, index);
      });
    },
    async createReferenceFirestore(path) {
      const compra_id = localStorage.getItem("compra_id");
      db.collection("fotos").add({
        url_original: path,
        url_editada: path.replace("original", "editada"),
        compra_id: compra_id,
        data: moment().format("DD/MM/YYYY"),
        horario: moment().format("hh:mm:ss"),
      });
      this.contagem += 1;
      if (this.contagem == this.listaDeFotosSelecionadas.length) {
        const docRef = doc(db, "compras", `${compra_id}`);
        await setDoc(docRef, { etapa: etapa.EDICAO }, { merge: true });
        this.$router.push(`/user/upload-sucesso`);
      }
    },
    createTaskUpload(imgBase64, index) {
      let self = this;
      let keyMap = localStorage.getItem("compra_id");

      const pathStorage =
        process.env.VUE_APP_ENV === "production"
          ? `public/${keyMap}/original/${index}`
          : `photos/${keyMap}/original/${index}`;

      var uploadTask = storageRef
        .child(pathStorage)
        .putString(imgBase64, "data_url");

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          console.log(error);
          this.$router.push(`/user/upload-falha`);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function () {
            self.createReferenceFirestore(pathStorage);
          });
        }
      );
    },
    removeFoto(item, indexOrigem) {
      this.listaDeFotosSelecionadas.forEach((element, index, arr) => {
        if (element.origemIndex == indexOrigem) {
          arr.splice(index, 1);
        }
      });
      this.listaMapSelected.forEach((element, index, arr) => {
        if (element == indexOrigem) {
          arr.splice(index, 1);
        }
      });
    },
    closeModal() {
      this.modal = false;
      this.modalMensagem = "";
    },
    openModal(mensagem) {
      this.modal = true;
      this.modalMensagem = mensagem;
    },
    verificaQuantidade() {
      if (this.getQuantidadeFotoSelecionada >= this.getQuantidadeMaxima) {
        // teste
        this.openModal(
          "Limite de imagens na compra atingido, remova uma para poder acrescentar outra"
        );
        return false;
      }
      return true;
    },
    async selecionaImagem(imagem_url, origemIndex) {
      if (!this.verificaQuantidade()) return false;
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      toDataURL(imagem_url).then((dataUrl) => {
        this.listaDeFotosSelecionadas.push({
          src: dataUrl,
          origemIndex: origemIndex,
        });
        this.listaMapSelected.push(origemIndex);
      });
    },
    getURLImagem(photoID, token) {
      serviceInstagram
        .getFotoForId(photoID, token)
        .then((resp) => {
          this.listagemDeFotos.push(resp);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    foreachMediaFoto(listaImagens, token) {
      var lista = listaImagens;
      lista.forEach((element) => {
        this.getURLImagem(element.id, token);
      });
    },
    async requestMedias() {
      let token = localStorage.getItem("ig-token");
      await serviceInstagram
        .getPaginationPhotos(token)
        .then((resp) => {
          this.foreachMediaFoto(resp.data, token);
        })
        .catch((err) => {
          console.error(err);
          alert("Problema na api, tente subir as imagens manualmente.");
          this.$router.push("/user/seleciona-metodo");
        });
    },
  },
};
</script>

<style></style>
